* {
  margin: 0;
  padding: 0;
}

main {
  box-sizing: border-box;
  background-color: rgb(245, 239, 224);
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  min-height: 100vh;
  /* max-width: 100%; */
}

p {
  font-size: 1.2rem;
}

.home p,
.introduction p,
.publication-text p,
.teaching p,
.teaching li,
.interview p,
.supervision p,
.accordion-body p {
  color: rgb(102, 102, 102);
  font-size: 1.15rem;
  text-align: justify;
  line-height: 1.5rem;
}

h5 {
  color: red !important;
  display: flex;
  justify-content: center;
}

/* UTILITY Classes */
.img-standard {
  width: 25rem;
  height: 35rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.publication-link {
  color: #b91629;
  text-decoration: none;
}

.publication-link:hover {
  color: red;
}

.text-blue {
  color: rgb(8, 62, 115) !important;
}

/* Navbar */
.navbar {
  position: relative;
}

.navbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, transparent, #ccc, transparent);
}

.navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-nav .dropdown-menu {
  margin-top: 0;
  background-color: rgb(245, 239, 224);
}

.navbar-menu-item a {
  color: #b91629 !important;
  font-size: 1rem;
}

.navbar-menu-item a:hover {
  color: red !important;
}

.keywords {
  font-size: 0.8rem;
  color: #6c757d;
  display: block;
  margin-top: 2px;
}

/* HOME */
.home {
  text-align: justify;
}

.btn-home {
  width: auto;
  text-decoration: none;
  font-style: italic;
  color: #b91629 !important;
  font-size: 1rem;
}

.btn-home:hover {
  color: red;
}

/* Introduction */
.introduction p {
  /* text-align: justify; */
}

.introduction img {
  width: 27rem;
  height: auto;
}

/* TEACHING */
.teaching p {
  text-align: justify;
}

.supervision h2 {
  /* color: rgb(8, 62, 115); */
}

.accordion-button {
  color: red !important;
}

/* PUBLICATIONS */
.publications-menu {
  width: 100%;
  color: #666;
  padding: 0 3rem 3rem;
}

.publications-menu a {
  text-decoration: none;
  font-size: 0.9rem;
  color: #b91629;
  margin-right: 0.8rem;
}

.publications-menu a:hover {
  color: red;
}

.publications {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.publication-text h2 {
  display: inline-block;
  padding: 1rem 0;
  border-top: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 500;
  text-align: center !important;
}

.publications > p {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.6;
  width: 80%;
}

.publications-list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.publication {
  display: flex;
  gap: 1.8rem;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s ease, border-width 0.3s ease;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 0.5rem 0;
}

.publication:hover {
  transform: translateY(-5px);
}

.publication:hover {
  text-decoration: none;
  border-color: rgb(201, 145, 145);
  border-width: 1px;
}

.publication img {
  width: 10rem;
  aspect-ratio: 1;
  align-self: start;
  object-fit: cover;
}

.publication-future {
  background-color: rgb(221, 180, 255);
}

.publication div {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0.4rem 0;
}

.publication h3 {
  font-size: 2rem;
  font-weight: 400;
}

.publication p {
  font-size: 1.3rem;
  line-height: 1.4;
  font-weight: 300;
  margin-bottom: auto;
}

.publication-nameCredit {
  display: block !important;
}

.publication a {
  text-decoration: none;
  color: black;
}

.publication a:hover {
  color: red;
}

/* Publication Page Styling */
.publication-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.publication-upper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.publication-upper img {
  max-width: 35%;
  height: auto;
}

.publication-text {
  text-align: center;
  max-width: 1000px;
}

/* Supervision Styling */
.supervision h4 {
  font-size: 1.25rem;
}

.supervision-training p {
  line-height: 1.3rem;
}

/* Exhibitions Styling */

.exhibitions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.exhibitions > p {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.6;
  width: 80%;
}

.exhibitions-list {
  list-style: none;
  display: grid;
}

.exhibitions h5 {
  color: rgb(0, 0, 0);
  font-family: "Lora", serif;
}

.exhibition {
  display: flex;
  gap: 1rem;
}

.exhibition img {
  /* width: 15.5rem; */
  width: 10rem;
  aspect-ratio: 1;
  align-self: start;
  object-fit: cover;
}

.exhibition div {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0.4rem 0;
}

.exhibition p {
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: auto;
}

.exhibition a {
  text-decoration: none;
  color: black;
}

.exhibition a:hover {
  color: red;
}

/* Interviews Styling */
.accordion-button {
  background-color: rgb(237, 223, 189) !important;
}

.accordion-body {
  background-color: rgb(245, 239, 224) !important;
}

.swiper-container {
  height: 30rem;
  width: 30rem;
}

.swiper-slide img {
  height: 30rem;
  width: 30rem;
  object-fit: cover;
}

.interview-summary p {
  line-height: 1.3rem;
}

.interview-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.interview-img {
  height: 30rem;
  width: 30rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.interview p {
  text-align: justify;
}

.p-interviewer {
  color: red;
  font-style: italic;
}

.btn-interviews {
  background-color: rgb(237, 223, 189) !important;
  color: black !important;
  margin: 1rem 0;
  font-weight: bold !important;
}

.btn-interviews:hover {
  background-color: rgb(196, 176, 130) !important;
}

/* NEWS Styling */
.news-header {
  color: rgb(191, 142, 0);
  margin-bottom: 0rem;
}

.news-date {
  text-decoration: underline;
  font-style: italic;
  font-size: 1rem;
}

.news-img-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0rem;
}

.news-img {
  width: 50%;
  height: auto;
}

.news-section-header {
  margin-top: 2rem;
}

.news-text {
  /* font-weight: bold; */
  text-align: justify;
}

.news-underline {
  text-decoration: underline;
}

.news-schedule {
  margin-top: 1rem;
}

.news-schedule p {
  color: rgb(51, 51, 51);
  font-size: 1.2rem;
  line-height: 1rem;
}

/* Media Queries */

@media (max-width: 350px) {
  .exhibition img {
    width: 8rem;
  }
}

@media (min-width: 351px) and (max-width: 412px) {
  .exhibition img {
    width: 9rem;
  }
}

@media (max-width: 694px) {
  .publication p {
    font-size: 1.3rem;
  }
}

@media (max-width: 1250px) {
  .publication-upper img {
    max-width: 40%;
    height: auto;
  }
}

@media (max-width: 1100px) {
  .publication-upper img {
    max-width: 50%;
    height: auto;
  }

  .news-img {
    width: 80%;
  }

  .news-schedule p {
    line-height: 1.2rem;
  }
}

@media (max-width: 768px) {
  .navbar-menu-item a {
    font-size: 1.1rem !important;
  }

  .sticky-mobile {
    position: sticky !important;
    top: 0;
    z-index: 1020;
  }

  .publication-upper img {
    max-width: 70%;
    height: auto;
  }

  .news-img {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .img-righty {
    height: 70%;
    object-position: right;
  }

  .img-lefty {
    height: 70%;
    object-position: left;
  }

  .introduction img {
    width: auto;
    height: 22rem;
    object-fit: cover;
    /* object-position: left; */
  }
}
